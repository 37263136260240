.container {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    display: flex;
}
.container .sideBar{
    width: 20%;
    border: 1px solid #eee;
    height: 100%;
}

/* px-4 py-2 text-[#42526E] font-bold */
.container .sideBar .link{
    padding: 8px 16px;
    color: #42526E;
    font-weight: bold;
}

.container .sideBar .active{
    color:  #1890FF;
}

.container .rightContent{
    width: 80%;
    border: 1px solid #eee;
    overflow-y: auto;
    height: 100%;
}

.container .rightContent .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    font-weight: bold;
    color: #172B4D;

}