.container{
    display: flex;
}
.box{
    width: 500px;
    height: 500px;
    border: 1px solid red;
}

@media screen and (min-width: 400px) {
    .container{
        display: block;
    }
}

@media screen and (min-width: 800px) {
    .container{
        display: flex;
    }
}